.auth-page {
  padding: 40px;
  font-family: var(--default-font-family-Dm-Sans);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: var(--gradient-green-black);
}

.auth-page h1 {
  text-align: center;
}

.auth-page .forgot-password-link {
  color: var(--color-green);
  text-align: end;
  margin-bottom: 10px;
}

.auth-page .auth-left,
.auth-page .auth-right {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-page .auth-left {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0 30px;
}

.auth-page .auth-left h2 {
  color: white;
  font-size: 2.5rem;
  font-family: var(--default-font-family-Syne);
  line-height: 3.5rem;
}

.auth-page .auth-left img {
  margin-bottom: 40px;
  margin-top: -300px;
}

.auth-page .auth-form-container {
  width: 100%;
  max-width: 450px;
  min-width: 350px;
  padding: 50px;
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.auth-form-container h1{
  font-family: var(--default-font-family-Syne);
}

.auth-page form {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.auth-page form input {
  border: 1px solid var(--color-gray-200);
}

.auth-page form .third-party-auth-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--color-gray-200);
  color: var(--color-gray-700);
  font-size: var(--default-font-size-16);
  background-color: white;
  box-shadow: none;
}

.login-page-button{
  font-family: var(--default-font-family-Dm-Sans);
}

.auth-page form a {
  text-decoration: underline;
  font-weight: bold;
  color: black;
}
.auth-page form p {
  text-align: center;
  color: var(--color-gray-500);
}

.third-party-auth-button + p {
  margin-top: 20px;
}

/* TODO: Add gradient to text on left side */
