.data-application-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 5%;
  margin-top: 1rem;
  padding-top: 5rem;
  background-color: var(--color-white);
  width: 100%;
  background-image: url("./../../../../../../assets/images/atticus/backgrounds/dataApplication/data-application-security-background.svg");
  background-position: bottom left;
  background-repeat: no-repeat;
}

.data-application-section h2 {
  font-size: 2rem;
  max-width: 41.75rem;
  margin: auto;
  margin-bottom: 1rem;
  font-family: var(--default-font-family-Syne);
  font-size: 3rem;
  font-weight: 600;
  line-height: 4.625rem;
  text-align: left;
  color: var(--color-gray-900);
}

.data-application-section-subheading {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  max-width: 47rem;
  margin: auto;
  font-family: var(--default-font-family);
  font-size: var(--default-font-size-18);
  font-weight: 300;
  line-height: 1.688rem;
  text-align: center;
  color: var(--color-gray-600);
}

.key-features-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 38.125rem;
}

.key-features-container img {
  width: 1rem;
  height: 0.875rem;
  margin-top: 0.3rem;
}

.key-features-container span {
  max-width: 36.313rem;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.875rem;
  text-align: left;
  color: var(--color-gray-100);
}

.key-features-sub-container {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  border-bottom: 1px solid var(--color-gray-600);
  padding: 6px 0px 12px 0px;
  color: var(--color-gray-700);
}

.key-features-sub-container span {
  color: var(--color-gray-700);
  font-family: var(--default-font-family-Dm-Sans);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.875rem;
  text-align: left;
}

.data-application-section-accordions-container {
  display: flex;
  align-items: flex-start;
  max-width: 76rem;
  margin: 3rem auto;
  gap: 10rem;
}

@media (max-width: 48rem) {
  .key-features-sub-container span {
    font-size: 1rem;
  }
  .data-application-section h2 {
    font-size: 1.5rem;
  }

  .data-application-section p {
    font-size: 1rem;
  }

  .data-application-section-accordions-container {
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 0rem;
  }
}
