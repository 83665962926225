.legal-team-member-container {
    display: flex;
    gap: 2rem;
    margin-top: 1.5rem;
}

.legal-team-member-back-btn {
    height: 1.5rem;
    width: 1.5rem;
    transition: all 0.3s ease;
    cursor: pointer;
}

.legal-team-member-back-btn:hover {
    transform: scale(1.1);
    background-color: var(--color-green-20);
    border-radius: 0.25rem;

}

.legal-team-member-profile,
.assigned-cases {
    background-color: var(--color-white);
    border-radius: 0.5rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    font-family: var(--default-font-family-Dm-Sans);
}

.legal-team-member-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    max-width: 25%;
    min-width: 15.375rem;
    margin: auto;
}

.legal-team-member-profile-image {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    margin: auto;
    background-color: var(--color-dark-green);
}

.profile-info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.profile-information-sub-container .profile-info-heading {

    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-14);
    font-weight: 400;
    line-height: 21px;
    color: var(--color-gray-500);
    text-align: center;
}

.profile-information-sub-container .profile-info-value {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-16);
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.profile-name {
    font-size: var(--default-font-size-18);
    font-weight: 600;
    color: var(--color-gray-900);
    font-family: var(--default-font-family-Syne);
    line-height: 21.6px;
    text-align: left;
}

.profile-role {
    font-size: var(--default-font-size-16);
    color: var(--color-gray-700);
    font-family: var(--default-font-family-Syne);
}


.assigned-cases {
    flex: 2;
    overflow-x: auto;
}

.assigned-cases h2 {
    font-family: var(--default-font-family-Syne);
    font-size: var(--default-font-size-18);
    margin-bottom: 1rem;
    color: var(--color-gray-900);
    font-weight: 600;
    line-height: 21.6px;
    text-align: left;
}

.assigned-cases-table {
    width: 100%;
    border-collapse: collapse;
    font-size: var(--default-font-size-14);

}

.assigned-cases-table tr {
    height: 63px;
}

.assigned-cases-table th {
    padding: 0.75rem;
    text-align: center;
    font-family: var(--default-font-family-Dm-Sans);
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    color: var(--Grays-700, #344054);
    border-bottom: 1px solid var(--color-gray-200);
}


.assigned-cases-table td {
    padding: 0.75rem;
    text-align: center;
    font-family: var(--default-font-family-Dm-Sans);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: var(--Grays-800, #1D2939);
}

.assigned-cases-table th:first-child {
    text-align: left;
}

.assigned-cases-table td:first-child {
    text-align: left;
}

.assigned-cases-table th,
.assigned-cases-table td {
    white-space: nowrap;

}

.assigned-cases-table td {
    border-bottom: 1px solid var(--color-gray-200);
}

.assigned-cases-table .status {
    background-color: var(--color-green-20);
    color: var(--color-dark-green);
    padding: 4px 12px 4px 12px;
    border-radius: 0.25rem;
    font-family: var(--default-font-family-Dm-Sans);
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
}


.assigned-cases-table .status.open {
    background-color: var(--color-green-20);
    color: var(--color-dark-green);
}

.assigned-cases-table .status.pending {
    background-color: #fff3cd;
    color: #856404;

}

.assigned-cases-table .status.closed {
    background-color: #f8d7da;
    color: #721c24;

}

.assigned-cases-table .action-icon {
    color: var(--color-gray-500);
    cursor: pointer;
}

.assigned-cases::-webkit-scrollbar {
    height: 0.5rem;
}

.assigned-cases::-webkit-scrollbar-track {
    background: transparent;
}

.assigned-cases::-webkit-scrollbar-thumb {
    background: var(--color-green-20);
    border-radius: 4px;
}

.assigned-cases::-webkit-scrollbar-thumb:hover {
    background: var(--color-dark-green);

}

@media (max-width: 62.5rem) {
    .legal-team-member-container {
        flex-direction: column;
    }
}