.meet-atticus-section-container {
  background: url("./../../../../../assets/images/atticus/backgrounds/meetAtticus/meet-atticus-top-bg-lines.png"), url("./../../../../../assets/images/atticus/backgrounds/meetAtticus/meet-atticus-bottom-bg-lines.png");
  background-repeat: no-repeat;
  background-size: 40% 30%, 40% 28%;
  background-position: top right, bottom left;
  background-color: var(--color-gray-50);
  width: 100%;
}
.meet-atticus-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 1.25rem;
}

.meet-atticus-content {
  max-width: 58.625rem;
  margin-bottom: 1.25rem;
}

.meet-atticus-sub-heading {
  font-family: var(--default-font-family-Syne);
  font-size: 3rem;
  font-weight: 600;
  line-height: 2.4rem;
  text-align: center;
  margin-top: 1rem;
  max-width: 938px;
}

.meet-atticus-description {
  max-width: 47rem;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-18);
  color: var(--color-gray-600);
  font-weight: 400;
  line-height: 1.688rem;
  margin: auto;
  text-align: center;
  padding: 0.938rem;
}

.meet-atticus-demo-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  margin-top: 1.9rem;
}

.meet-atticus-slider-container {
  max-width: 76rem;
  position: relative;
  margin-top: 1.5rem;
}

.meet-atticus-heading {
  font-family: var(--default-font-family-Syne);
  font-size: 3rem;
  font-weight: 600;
  line-height: 74px;
  text-align: center;
}

@media (max-width: 48rem) {
  .meet-atticus-heading {
    font-size: 3rem;
    line-height: 3.75rem;
    text-align: center;
  }

  .atticus-sub-heading {
    font-size: 1.4rem;
  }
  .atticus-description {
    font-size: var(--default-font-size-16);
  }
}

@media (max-width: 24rem) {
  .demo-first-sub-card {
    max-width: 15.75rem;
    top: 4.063rem;
    left: 23%;
  }
  .demo-second-sub-card {
    top: 43%;
    left: 25.5%;
    width: 15.75rem;
  }
  .demo-top-right-image {
    right: 23%;
  }
}
