.drag-file-upload {
  border: 1px dashed var(--color-grey-400);
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 1.5rem;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.3s;
}

.drag-file-upload-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.drag-upload-icon {
  font-size: 1.5rem;
  margin-right: 0.6rem;
}

.drag-upload-text {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-16);
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: var(--Grays-1000, #101010);
}

.drag-upload-info {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-16);
  font-weight: 400;
  line-height: 20.02px;
  letter-spacing: 0.17000000178813934px;
  text-align: center;
  color: var(--color-gray-600);
}

.click-to-upload-text {
  color: var(--color-dark-green);
  text-decoration: underline;
}
.drag-file-upload:hover {
  background-color: var(--color-white);
}

.drag-drag-active {
  background-color: var(--color-white);
  border-color: var(--color-dark-green);
}
