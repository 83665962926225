.document-item-card {
    min-width: 34rem;
    min-height: 6.25rem;
    padding: 1.5rem;
    gap: 1.5rem;
    border-radius: 12px;
    background: var(--Grays-0, #FFFFFF);
    width: 100%;
}

.document-item-card-file-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 1px dashed var(--Grays-200, #EAECF0);
    border-radius: 6px;
    padding-right: 8px;

}

.document-item-card .collapsible-card {
    padding: 0;
}

.document-item-card-file-icon {
    height: 100%;
    width: 3.125rem;
}

.document-file-left-content {
    display: flex;
    align-items: center;
    gap: 8px;
}

.document-item-card-text {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-14);
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.42424243688583374px;
    text-align: left;
    color: var(--color-gray-800);
}

.document-file-summarize-button {
    display: flex;
    align-items: center;
    background-color: var(--color-white);
    height: 2rem;
    gap: 0.32625rem;
    margin-left: 0.625rem;
    border-radius: 0.32625rem;
    border: 0.040625rem solid var(--color-green);
    font-family: var(--default-font-family-Dm-Sans);
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 0.73375rem;
    text-align: center;
    color: var(--color-gray-900);

}

.summarize {
    border: 0.040625rem solid var(--color-gray-800);
}

.summarize-unavailable{
    background-color: var(--color-gray-200);
    border: 0.040625rem solid var(--color-gray-200);
    cursor: default !important;
}