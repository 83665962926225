.chat-history-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chat-history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.chat-history-header h2 {
  font-family: var(--default-font-family-Syne);
  font-size: var(--default-font-size-18);
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  color: var(--color-black);
  margin: 0;
}

.chat-history-add-chat-button {
  font-size: 26px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: black;
  padding: 0;
}

.chat-history-add-chat-button:hover {
  color: #0056b3;
}

.chat-history-list {
  overflow-y: auto;
}

.chat-history-date-label {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: 12px;
  font-weight: 500;
  line-height: 15.62px;
  text-align: left;
  color: var(--color-gray-600);
  margin: 15px 0 5px;
}

.chat-history-item {
  padding: 10px;
  padding-left: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-18);
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: var(--Grays-900, #101828);
  cursor: pointer;
  transition: background-color 0.3s, padding-left 0.3s;
}

.chat-history-item:hover {
  background-color: #f1f1f1;
  padding-left: 10px;
}

.chat-history-item.active-conversation {
  background: var(--gradient-green-teal);
  color: var(--color-white);
}

.chat-history-list::-webkit-scrollbar {
  width: 5px;
}

.chat-history-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.chat-history-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}
