.demo-slider-container {
  width: 100%;
  max-width: 76rem;
  margin: auto;
}

.demo-slide-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 76rem;
  width: 100%;
  min-height: 30.875rem;
  margin: 0 auto;
  cursor: pointer;
}

.demo-slide-content-heading {
  font-family: var(--default-font-family-Syne);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.8rem;
  text-align: left;
  color: var(--color-black);
  margin-bottom: 2rem;
}

.demo-sub-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}

.demo-slider-main-card {
  background-color: var(--color-white);
  max-width: 30.5rem;
  width: 100%;
  min-height: 24.5rem;
  gap: 0px;
  border-radius: 1rem;
  padding: 1.875rem;
  background: var(--color-gray-100);
  box-shadow: 0 0.188rem 0.625rem rgba(0, 0, 0, 0.1);
  position: relative;
}

.demo-top-right-image {
  position: absolute;
  top: 7%;
  right: 9%;
  width: 4.188rem;
}

.demo-first-sub-card,
.demo-second-sub-card {
  position: absolute;
  background-color: var(--color-white);
  border-radius: 0.474rem;
  box-shadow: 0.93rem 0.93rem 2.47rem 0px #132d611a;
}

.demo-first-sub-card {
  max-width: 21.961rem;
  min-height: 10.092rem;
  top: 4.063rem;
  left: 2.75rem;
  padding: 1.376rem;
  z-index: 1;
}

.demo-second-sub-card {
  width: 21.85rem;
  min-height: 10.09rem;
  top: 43%;
  left: 18%;
  padding: 1.376rem;
  border-radius: 0.474rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease, z-index 0.3s ease;
}

.demo-second-sub-card:hover {
  z-index: 2;
  transform: translateY(-0.625rem) scale(1.05);
  box-shadow: 0.93rem 0.93rem 3.125rem 0.3125rem rgba(19, 45, 97, 0.2);
}

.demo-file-container {
  display: flex;
  align-items: center;
  padding: 0;
  border: 0.0575rem dotted var(--color-gray-200);
  border-radius: 0.5rem;
  gap: 0.5rem;
  background: var(--color-light-grey);
}

.demo-summarize-button {
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  border: 0.0575rem solid var(--color-grey-400);
  border-radius: 0.457rem;
  padding: 0.625rem 1.25rem;
  margin-bottom: 1.8125rem;
  gap: 0.458rem;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: 0.687rem;
  font-weight: 500;
  line-height: 1.031rem;
  text-align: center;
  color: var(--color-gray-900);
}

.demo-sub-card-text {
  font-size: var(--default-font-size-1rem);
  color: var(--color-gray-700);
}

.demo-slide-content {
  padding-left: 2.5rem;
  max-width: 36.875rem;
}

.demo-slide-content-description {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  color: var(--color-gray-600);
}

.demo-slider-dots {
  display: flex;
  gap: 0.625rem;
  justify-content: left;
  margin-top: 1.25rem;
}

.demo-slider-dot {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background-color: var(--color-grey-300);
  cursor: pointer;
}

.demo-slider-dot:hover {
  background-color: var(--color-grey-400);
  transform: scale(1.2);
}

.demo-slider-dot.active {
  background-color: var(--color-green);
  width: 1.25rem;
  border-radius: 0.625rem;
}

@media (max-width: 75.313rem) {
  .demo-sub-container {
    flex-direction: column;
  }
  .demo-slide-content-heading {
    margin-top: 2rem;
  }
  .demo-slider-dots {
    justify-content: center;
  }

  .demo-slide-content {
    max-width: 30.5rem;
    padding: 0;
  }
}

@media (max-width: 35.625rem) {
  .demo-first-sub-card {
    top: 4.063rem;
    left: 2.75rem;
  }
  .demo-second-sub-card {
    top: 43%;
    left: 20%;
  }
  .demo-slide-content-heading {
    max-width: 60%;
    text-align: center;
    margin: 2rem auto;
  }

  .demo-slide-content-description {
    max-width: 60%;
    text-align: center;
    margin: 2rem auto;
  }
}

@media (max-width: 30.5rem) {
  .demo-first-sub-card {
    max-width: 18.75rem;
    top: 4.063rem;
    left: 16%;
  }
  .demo-second-sub-card {
    top: 43%;
    left: 22%;
    width: 18.75rem;
  }
}

@media (max-width: 24rem) {
  .demo-first-sub-card {
    max-width: 15.75rem;
    top: 4.063rem;
    left: 23%;
  }
  .demo-second-sub-card {
    top: 43%;
    left: 25.5%;
    width: 15.75rem;
  }
  .demo-top-right-image {
    right: 23%;
  }
}
