.custom-case-team-member-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--color-dark-green);

}

.custom-case-team-member-image-extra {
    background-color: var(--color-dark-green);
    color: var(--color-white);
    font-family: var(--default-font-family-Syne);
    font-size: var(--default-font-size-18);
    font-weight: 400;
    line-height: 27px;
    text-align: left;
}