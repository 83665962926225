.role-details-card {
  padding: 1rem;
  gap: 1rem;
  border-radius: 0 1rem 1rem 1rem;
  background-color: var(--color-white);
  max-width: 80%;
}

.role-details-title {
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-family: var(--default-font-family-Dm-Sans);
  font-weight: 400;
  line-height: 1.6875rem;
  text-align: left;
}

.role-details-content {
  display: flex;
  align-items: center;
}

.role-details-content form {
  display: flex;
  width: 100%;
  gap: 10px;
}

.role-details-content input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--Grays-200, hsla(220, 17%, 93%, 1));
  border-radius: 0.25rem;
  min-width: 250px;
}
.role-details-content input:focus {
  border: 1.5px solid var(--color-grey-300);
}

.role-details-content button {
  width: 100%;
}
