.logout-page h1 {
  text-align: center;
  background: var(--gradient-green-teal);
  padding: 20px;
  border-radius: 8px;
}

.logout-page {
  padding: 80px 20px;
}
