.selected-file-container {
  display: flex;
  align-items: center;
  min-width: 16rem;
  height: 52px;
  padding: 0.5rem;
  gap: 1rem;
  border-radius: 6px;
  border: 1px solid var(--color-grey-300);
  text-align: left;
}

.selected-file-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.selected-file-name {
  flex-grow: 1;
}

.selected-delete-icon {
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
}
