.chat-role-selection-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}

.chat-role-item-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-300);
  border-radius: 0.75rem;
  min-width: 300px;
  flex: 1 1 calc(50% - 1rem);
  width: 100%;
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.chat-role-item-container.analyze {
  flex: 1 1 calc(30% - 1rem);
}

.chat-role-item-heading-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.chat-role-item-heading-container input[type='checkbox'] {
  appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid var(--color-gray-200);
  border-radius: 3px;
  position: relative;
  transition: border-color 0.3s ease;
  cursor: pointer;
}

.chat-role-item-heading-container input[type='checkbox']:checked {
  border-color: var(--color-white);
  position: relative;
}

.chat-role-item-heading-container input[type='checkbox']:checked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  background-image: url('@icons/check-box-icon.svg');
  background-size: cover;
  background-repeat: no-repeat;
}

.chat-role-item-container.selected {
  position: relative;
  border: none;
}

.chat-role-item-container.selected ::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 0.75rem;
  padding: 2px;
  background: var(--gradient-green-teal);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.chat-role-item-container.disabled {
  opacity: 0.5;
}

.chat-role-content {
  display: flex;
  flex-direction: column;
}

.chat-role-item-title {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-18);
  font-weight: 500;
  line-height: 1.688rem;
  text-align: center;
  margin: 0;
  padding: 0;
  color: var(--Grays-1000, #101010);
}

.chat-role-description {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-16);
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  color: var(--color-gray-800);
  padding: 0;
  margin: 0;
}
