.atticus-backdrop-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.atticus-loader-container {
  width: 5.625rem;
  height: 5.625rem;
  background-color: #ccc;
  border-radius: 50%;
  position: relative;
  z-index: 5;
}

.atticus-spinner-container {
  width: 7.5rem;
  height: 7.5rem;
  border: 0.5rem solid var(--color-green);
  border-top: 0.5rem solid #dcdcdc;
  border-radius: 50%;
  position: absolute;
  z-index: 100;
}

.atticus-loader-logo-container {
  position: absolute;
  width: 4.375rem;
  height: 4.375rem;
  z-index: 10;
}
