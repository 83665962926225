.docket-modal-main-container {
    padding: 1.2rem 1.3rem 0 1.3rem; 
    gap: 2rem; 
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
}

.docket-modal-header-section {
    width: 100%; 
    min-height: 2.5rem; 
    gap: 0.8125rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.docket-modal-header-section h3 {
    font-weight: 500;
    font-size: 1.125rem; 
    line-height: 1.35rem; 
    color: var(--color-black);
}

.docket-modal-all-buttons {
    display: flex;
    flex-direction: row;
    gap: 0.625rem; 
    align-items: center;
}

.docket-modal-skip-entry-btn {
    font-size: 0.75rem; 
    color: var(--color-gray-500);
    background-color: var(--color-white);
    border: 0.0625rem solid var(--color-gray-500); 
}

.docket-modal-load-pacer-btn {
    color: var(--color-gray-900);
    font-size: 0.75rem; 
    background-color: var(--color-light-green);
    font-weight: 600;
}

.button-upload-docket-modal {
    font-size: 0.75rem; 
    padding: 0.4rem 1.2rem;
}

.button-content-docket-modal {
    display: flex;
    gap: 0.3125rem;
    align-items: center;
    margin-left: -0.3125rem; 
    color: var(--color-white);
}

.docket-modal-table-section-main {
    width: 100%;
}

.docket-modal-table {
    width: 100%;
    height: min-content;
    border-collapse: collapse;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    font-family: var(--default-font-family-Dm-Sans);
}

.docket-modal-table th {
    width: 12.125rem;
    height: 1.3125rem;
    color: var(--color-gray-600);
    padding: 0.6rem 0.2rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    border-bottom: 0.0625rem solid var(--color-gray-100);
}

.docket-modal-table td {
    text-align: left;
    padding: 0.4rem 0;
    border-bottom: 0.0625rem solid var(--color-gray-100);
    color: var(--color-black);
}
