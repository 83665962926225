.footer {
  padding: 5rem;
  gap: 4.063rem;
  background-color: var(--color-black);
  margin: auto;
  color: var(--color-white);
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-section {
  flex: 1;
  margin: 0.625rem;
  min-width: 12.5rem;
}

.logo-section {
  max-width: 25rem;
}

.footer-logo {
  width: 9.375rem;
  margin-bottom: 1.25rem;
}

.footer-text {
  margin-bottom: 1.25rem;
  max-width: 19.813rem;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-16);
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
}

.newsletter form {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}
.newsletter input {
  color: var(--color-white);
  font-family: var(--default-font-family-Dm-Sans);
  min-width: 14.563rem;
  font-size: var(--default-font-size-16);
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  background: linear-gradient(95.55deg, #1d1d21 18.01%, rgba(29, 29, 33, 0.17) 52.48%, rgba(29, 29, 33, 0.96875) 92.15%);
}
.footer-text-field {
  max-width: 14.688rem;
  border: 1px solid var(--color-gray-500);
  border-radius: 0.5rem;
  background: linear-gradient(95.55deg, #1d1d21 18.01%, rgba(29, 29, 33, 0.17) 52.48%, rgba(29, 29, 33, 0.96875) 92.15%);
  color: var(--color-white);
}

.subscribe-btn {
  padding: 0.625rem 1.25rem;
  border: none;
  border-radius: 5px;
  background-color: #3bc177;
  color: #000;
  cursor: pointer;
}

.footer-social-icons {
  margin-top: 2rem;
  display: flex;
  gap: 0.625rem;
}

.footer-social-icons img {
  height: 38px;
  width: 38px;
}

.links-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 57.688rem;
}

.footer-links {
  flex: 1;
  margin: 0 0.625rem;
}

.footer-links h3 {
  margin-bottom: 1.25rem;
  font-family: var(--default-font-family-Syne);
  font-size: var(--default-font-size-18);
  font-weight: 600;
  line-height: 21.6px;
}

.footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 0.625rem;
}

.footer-pages-links {
  color: var(--color-grey-300);
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-16);
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  text-decoration: none;
}

.footer-pages-links.active {
  color: var(--color-green);
}

.footer-links a {
  text-decoration: none;
  color: var(--color-grey-300);
  color: var(--color-grey-300);
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-16);
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  text-decoration: none;
}

.footer-links a:hover {
  color: #3bc177;
}

.footer-bottom {
  text-align: center;
  margin-top: 40px;
  border-top: 1px solid var(--color-green);
  padding-top: 1.25rem;
  opacity: 20%;
}

.copy-right {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-16);
  font-weight: 400;
  line-height: 1.5rem;
  color: #d0d5dd;
  opacity: 1;
  text-align: center;
}

.newsletter button {
  padding: 1rem;
  height: 100%;
  cursor: default !important;
}

@media (max-width: 56.25rem) {
  .newsletter form {
    flex-wrap: wrap;
  }
}
@media (max-width: 48rem) {
  .footer {
    padding: 5.5rem 4%;
  }
  .newsletter input {
    min-width: 12.5rem;
    width: 100%;
  }
}
