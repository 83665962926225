.dashboard-navigation {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: 264px;
  box-sizing: border-box;
  padding: 1rem;
  position: relative;
  height: 100%;
}


.dashboard-navigation-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  height: 4rem;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.dashboard-nav-image {
  margin: 0.7rem auto;
}

.dashboard-navigation-logo-container img {
  height: 130%;
}


.dashboard-navigation-text-logo-container p {
  font-weight: 600;
  margin: 0;
  font-size: 1.5rem;
}

.dashboard-navigation-text-logo-container-gradient-box {
  background: var(--gradient-green-teal);
  border-radius: 3px;
  padding: 0.2rem 0.4rem;
  margin-top: -0.25rem;
}

.dashboard-navigation-text-logo-container-gradient-box p {
  font-size: 0.45rem;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--color-white);
}

.dashboard-navigation-special-link {
  text-decoration: underline;
  text-align: center;
  font-weight: 400;
  margin: 1.6rem 0;
  font-family: var(--default-font-family-Dm-Sans);
}

.dashboard-navigation-list-items {
  height: 100%;
  flex: 1;
}



.dashboard-navigation-list-items .navigation-case-title {
  font-family: var(--default-font-family-Dm-Sans);
  color: var(--color-gray-900);
  font-size: 12px;
  font-weight: 500;
  line-height: 15.62px;
  text-align: left;
  margin-top: 1rem;
}