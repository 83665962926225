.custom-accordion {
  max-width: 25.75rem;
}
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

.custom-accordion-item {
  border: none;
  margin-bottom: 1px;
  border-bottom: 1px solid var(--color-gray-700);
}

.custom-accordion-item:last-child {
  border-bottom: none;
}
.accordion-item {
  background-color: transparent;
}
.accordion-section-header {
  font-family: var(--default-font-family-Syne);
  color: var(--color-gray-900);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.8rem;
  text-align: left;
  padding: 0.75rem;
  margin: 0;
  display: flex;
  align-items: center;
  position: relative;
}

.accordion-section-header::before {
  content: "";
  position: absolute;
  left: 0;
  top: 25%;
  bottom: 25%;
  width: 2px;
  background-color: var(--color-grey-300);
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
}

.accordion-section-header.active::before {
  background: var(--color-dark-green);
  width: 4px;
}

.accordion-section-header:focus {
  outline: #111;
}

.custom-accordion-header::after {
  display: none;
}
.accordion-button::after {
  display: none;
}

.accordion-button:focus {
  box-shadow: none;
}

.custom-accordion-body {
  border-top: none;
  color: var(--color-gray-600);
  padding: 0.2rem;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-18);
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  max-width: 31.625rem;
  padding-bottom: 1rem;
}

.visit-website-btn {
  background: linear-gradient(90deg, rgba(34, 193, 195, 1) 0%, rgba(45, 253, 45, 1) 100%);
  border: none;
  color: #ffffff;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  display: inline-flex;
  align-items: center;
  font-size: 0.875rem;
}

.visit-website-btn:hover {
  background: linear-gradient(90deg, rgba(45, 253, 45, 1) 0%, rgba(34, 193, 195, 1) 100%);
}

.software-image img {
  max-width: 43.25rem;
  height: auto;
}

@media (max-width: 75rem) {
  .software-suites-content {
    flex-direction: column;
    gap: 2rem;
  }

  .software-image {
    margin-top: 1rem;
  }

  .software-image img {
    width: 37.5rem;
  }
}

@media (max-width: 49.125rem) {
  .accordion-section-header {
    font-size: 1.2rem;
  }
  .software-suites-content {
    flex-direction: column;
    gap: 2rem;
  }

  .software-image {
    margin-top: 1rem;
  }

  .software-image img {
    width: 18.75rem;
  }
}

@media (max-width: 21.875rem) {
  .software-image img {
    width: 13.75rem;
  }
}
