.timeline-item {
    display: flex;
    position: relative;
    align-items: center;
}

.timeline-icon-container {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.timeline-icon {
    z-index: 1;
}


.timeline-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.timeline-header {
    display: flex;
    flex-direction: column;
    min-width: 170px
}

.timeline-header .timeline-date {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-16);
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: var(--Grays-1000, #101010);
}

.timeline-header .timeline-role {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-14);
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.42424243688583374px;
    text-align: left;
    color: var(--color-gray-800);
}

.document-list {
    display: flex;
    flex-direction: column;
}