.text-block {
    border-radius: 12px;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #000000;
}

.text-block-icon {
    width: 24px;
    height: 24px;

}