.trial-generated-content {
  background-color: var(--color-white);
  margin-top: 2px;
  border-radius: 1rem;
  font-family: Poppins;
  padding: 3rem;
  box-shadow: 0 4px 8px rgba(161, 161, 161, 0.1);
}

.trial-section {
  margin-top: -10px;
  color: var(--color-gray-800);
}

.trial-title {
  font-size: var(--default-font-size-16);
  text-align: left;
  margin-left: -18px;
  font-weight: 600;
  margin-top: 28px;
}

.trial-points-list {
  text-align: justify;
  list-style-type: none;
  list-style-type: disc;
  font-size: var(--default-font-size-16);
}

.trail-generated-last-message {
  text-align: justify;
  margin-top: 2.3rem;
  margin-left: -40px;
}
.download-container-trial-page {
  text-align: left;
  margin-left: -40px;
  margin-top: 22px;
}

.download-text-trial-page {
  color: var(--color-dark-green);
  font-weight: 500;
  margin-left: 1rem;
  text-decoration: underline;
  text-underline-offset: 2px;
  cursor: pointer;
}
.retry-icon-trial-page {
  margin-left: 1rem;
}
.trial-workflow-container .chat-item-content-container {
  align-items: center;
}
