#cases-table-filter-dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid var(--color-gray-200) !important;
  width: 100%;
  height: 100%;
}

#cases-table-filter-dropdown p {
  margin-top: 13px;
}

.case-search-filter-dropdown {
  border-radius: 4px;
}

#cases-table-filter-dropdown::after {
  display: none;
  border: none;
}

.cases-dropdown-toggle-text {
  margin: 0 !important;
}

.cases-index-table-row:not(:first-of-type) {
  border-bottom: 2px solid var(--color-gray-200);
}

.cases-header-dropdown-option {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: var(--color-gray-600);
  border: 2px solid var(--color-gray-200) !important;
  background-color: transparent;
  border-radius: 4px;
  width: 100%;
}
