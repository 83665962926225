.chat-welcome-header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 1.5rem 0px;
  background: var(--color-gray-50);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.chat-welcome-header-container h3 {
  font-family: var(--default-font-family-Syne);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.8rem;
  text-align: left;
  color: var(--color-gray-900);
  padding: 0;
}

.chat-welcome-header-container img {
  height: 1rem;
  width: 1rem;
}
