.chat-welcome-section {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.chat-welcome-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    gap: 2rem;
    text-align: center;
}

.chat-welcome-content {
    flex: 1;
    overflow-y: auto;
    scrollbar-width: none;
}
.chat-welcome-content::-webkit-scrollbar {
    display: none;
}

.chat-welcome-header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 1rem;
}

.chat-welcome-header img {
    height: 3.75rem;
    width: 3.75rem;
    margin: auto;
}

.chat-welcome-header h2 {
    font-family: var(--default-font-family-Syne);
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.4rem;
    text-align: center;
    color: var(--color-gray-800);
    padding: 0;
    margin: 0;
}

.chat-welcome-header p {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-18);
    font-weight: 400;
    line-height: 1.688rem;
    text-align: center;
    color: var(--color-gray-800);
}

.chat-welcome-features-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3.125rem;
    row-gap: 1.25rem;
}