.notification-badge {
  display: flex;
  align-items: center;
  justify-content: center; 
  background-color: black;
  color: white;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1rem;
  line-height: 1;
  padding: 0 0.2rem;
}