.analyze-top-heading-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: left;
}

.analyze-top-heading-container h6 {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-18);
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  margin: 0;
  padding: 0;
}

.analyze-top-heading-container p {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-16);
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  padding: 0;
  margin: 0;
}

.analyze-chat-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.analyze-chat-content .chat-role-selection-container {
  gap: 2rem;
}
