.cases-index-search-form-container .pacer-search-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
}

.cases-index-search-form-container
  .pacer-search-form
  .pacer-search-text-input-container {
  flex: 3;
}

.cases-index-search-form-container
  .pacer-search-form
  .pacer-search-submit-button {
  flex: 1;
  height: 70px;
}

.cases-index-search-button{
  height: 70px;
  padding: 1rem 5rem !important;
  color: var(--color-gray-600) !important;
  border: 1px solid var(--color-gray-600) !important;
}


