:root {
  /* background: ; */
  --gradient-green-teal: linear-gradient(90deg, #7de264 0%, #59beb5 100%);
  --gradient-white-to-pale-green: linear-gradient(
    90deg,
    #ffffff 0%,
    #cedeb3 100%
  );
  --gradient-semi-transparent-green: linear-gradient(
    154.2deg,
    rgba(53, 173, 130, 0.4) -14.45%,
    rgba(5, 5, 15, 0.008) 55.4%
  );
  --gradient-semi-transparent-gray: linear-gradient(
    114.41deg,
    #ffffff -44.59%,
    rgba(214, 214, 214, 0.22) 44.26%,
    rgba(255, 255, 255, 0.97619) 153.41%
  );

  --gradient-green-dark-green: linear-gradient(
    90deg,
    rgba(70, 125, 71, 1) 0%,
    rgba(53, 107, 110, 1) 100%
  );
  --gradient-green-black: radial-gradient(
    circle at 20% 20%,
    rgb(27, 45, 44),
    rgba(0, 0, 0, 0.9) 60%
  );

  /* colors */
  --color-black: #101010;
  --color-white: #ffffff;
  --color-green: #7de264;
  --color-dark-green: #2dc008;
  --color-dark-green-2: #46cb25;
  --color-light-green: #b9ff98;
  --color-light-green-2: #f1ffea;
  --color-teal: #59beb5;
  --color-sky-blue: #49afdb;
  --color-green-20: #7de26433;
  --color-cyan: #6bfff7;
  --color-red: #e41d57;
  --color-light-red: #ffd5dd;
  --color-dark-red: #ff2d55;
  --color-grey-400: #98a2b3;
  --color-grey-300: #d0d5dd;
  --color-gray-200: #eaecf0;
  --color-gray-50: #f9fafb;
  --color-gray-100: #f2f4f7;
  --color-gray-700: #344054;
  --color-gray-500: #667085;
  --color-gray-600: #475467;
  --color-gray-800: #1d2939;
  --color-gray-900: #101828;

  /* font sizes */
  --default-font-size-14: 0.875rem;
  --default-font-size-16: 1rem;
  --default-font-size-18: 1.125rem;

  /* font family */
  --default-font-family: 'Archivo';
  --default-font-family-Syne: 'Syne';
  --default-font-family-Dm-Sans: 'DM Sans';
  --default-font-family-Inter: 'Inter';
  --default-font-family-EB-Garamond: 'EB Garamond';
}

.danger {
  background-color: var(--color-red) !important;
}

.button-disabled {
  cursor: default !important;
}

.cursor-pointer:hover {
  cursor: pointer !important;
}

.rounded-border {
  border-radius: 0.75rem !important;
}

.white-background {
  background-color: var(--color-white) !important;
}

.text-green-dark {
  color: var(--color-dark-green) !important;
}
