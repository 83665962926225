.loader-spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.loader-text {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-16);
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
}
