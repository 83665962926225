.header {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  position: relative;
}

.nav-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.logo img {
  height: 2.5rem;
}
.nav-logo-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.nav--list-container {
  display: flex;
  align-items: center;
}

.nav__list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
}

.nav__item {
  margin-left: 2rem;
  position: relative;
}

.nav__link {
  text-decoration: none;
  color: var(--color-gray-600);
  font-weight: 500;
  font-family: var(--default-font-family-Dm-Sans);
}

.nav__link--active {
  color: var(--color-green);
}

.nav__item--cta .nav__link {
  padding: 0.5rem 1rem;
  border: 1px solid var(--color-green);
}

.nav__item--dropdown .dropdown {
  display: none;
  position: absolute;
  top: 1.5rem;
  left: 0;
  width: 220px;
  padding: 1.5rem;
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  list-style: none;
  margin: 0;
  z-index: 1;
}

.pricing-dropdown-text {
  text-decoration: none;
  color: var(--color-gray-600);
  font-weight: 500;
  font-family: var(--default-font-family-Dm-Sans);
}
.borderd {
  border-bottom: 1px solid var(--color-gray-200);
}

.nav__item--dropdown:hover .dropdown {
  display: block;
}

.dropdown__item {
  margin: 0;
}

.dropdown__link {
  text-decoration: none;
  color: var(--color-gray-600);
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  font-weight: 500;
  size: 12;
  line-height: 1.125rem;
}

.dropdown__link:hover {
  background-color: var(--color-grey-300);
  border-radius: 0.3125rem;
}

.menu-toggle {
  display: none;
  align-items: center;
  justify-content: center;
  background: none;
  border: 2px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  padding: 0;
  transition: border-color 0.3s ease;
}

.menu-toggle:hover {
  border-color: rgba(0, 0, 0, 0.3);
}

.menu-toggle:active {
  border-color: rgba(0, 0, 0, 0.5);
}

.menu-icon {
  width: 1.6rem;
}

.dropdown-link {
  display: flex;
  gap: 5px;
  align-items: center;
}

@media (max-width: 67rem) {
  .nav--list-container {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--color-black);
    padding: 1rem 0;
  }
  .nav__list {
    flex-direction: column;
  }
  .nav--open {
    display: flex;
    z-index: 1;
  }

  .nav__item {
    margin: 1rem 0;
    text-align: center;
  }

  .menu-toggle {
    display: flex;
  }
}
