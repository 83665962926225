.date-picker-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.4375rem 1rem;
    gap: 0.625rem;
    border-radius: 0.5rem;
    border: 1px solid var(--color-gray-200);
}

.date-picker-input {
    width: 100% !important;
    font-size: var(--default-font-size-16);
    padding: 0.625rem;
    font-size: var(--default-font-size-16);
    border: none;
    outline: none;
    background: none;
    color: #333;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.calendar-icon {
    position: absolute;
    right: 0.625rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: var(--default-font-size-18);
}

.date-picker-input::placeholder {
    color: var(--color-grey-400);
}