@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap');

:root {
  --color-primary: ##2dc008; /* Light Green */
  --color-secondary: #344054; /* Dark Green */
  --color-tertiary: #b9ff98; /* Yelow Green */
  --color-background-main: #fcffff; /* White */
  --color-gradient-primary: linear-gradient(
    90deg,
    rgba(125, 226, 101, 1) 0%,
    rgba(90, 190, 180, 1) 100%
  );
  --color-gradient-secondary: linear-gradient(
    90deg,
    rgba(70, 125, 72, 1) 0%,
    rgba(54, 107, 110, 1) 100%
  );
  --color-font-primary: #0f1828; /* Black */
  --color-danger: #f22a5b; /* Red */
  --color-accent: #98a2b3; /* Grey */
  --color-gray: #f2f4f7;
  --color-white-off: #fcfcfc;

  font-size: 16px;
}

body {
  font-family: 'Syne', Geneva, Tahoma, sans-serif;
}

/* Take whole screen's width
html, body {
  height: 100px;
  /* min-height: 100vh; */
/* margin: 0; */
/* padding: 0; */
/* } */

html,
body {
  min-height: 100vh;
}

#root {
  height: 100%;
}

a {
  text-decoration: inherit;
  color: inherit;
}

/* TODO: Add dark theme colors */
/* [data-theme="dark"] {
} */

/* Resets */

ul {
  list-style-type: none;
  padding: 0;
}

/* Utility Classes */

.background-gradient-primary {
  background: var(--color-gradient-primary);
}

.hover-cursor {
  cursor: pointer;
}

/* TODO: Remove this old CSS */
.css-m0ngsp {
  margin: 0 !important;
}

.modal-backdrop.show {
  opacity: 0;
}

.modal-backdrop {
  z-index: 0;
}
