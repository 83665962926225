.chat-argument-message {
  border-radius: 0 8px 8px 8px;
  margin: 10px 0;
  width: fit-content;
  justify-content: center;
  padding: 10px;
  display: flex;
  background-color: white;
  flex-direction: column;
  text-align: left;
  font-family: var(--default-font-family-Dm-Sans);
}

.chat-argument-message p {
  margin: 0;
}

.chat-argument-message.user-chat-message {
  margin-left: auto;
  background-image: var(--gradient-green-dark-green);
  color: var(--color-white);
  padding: 16px;
  gap: 16px;
  border-radius: 16px 0px 16px 16px;
}
