.pacer-search-text-input-container {
  position: relative;
  margin: 2rem 0;
}

/* Text input */
.pacer-search-text-input {
  height: 3.5rem;
  width: 100%;
  position: relative;
  padding: 35px 68px;
  font-size: 1.125rem;
  outline: none;
  border: none;
  border-radius: 8px;
}

.pacer-search-text-input::placeholder {
  color: var(--color-grey-400);
}

.pacer-search-text-input-icon {
  position: absolute;
  left: 25px;
  top: 30%;
}

/* Submit button */
.pacer-search-submit-button {
  background: var(--gradient-green-teal);
  border: none;
  border-radius: 8px;
  padding: 1rem 1rem;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.pacer-search-submit-button p {
  margin: 0;
  font-family: var(--default-font-family-Dm-Sans);
}