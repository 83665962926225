.button-primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-gradient-primary);
  color: white;
  font-weight: bold;
  padding: 0.8rem 0;
  width: 100%;
  font-size: 0.8rem;
  border-radius: 4px;
  gap: 0.8rem;
  margin: 0 auto;
  border: none;
  font-family: var(--default-font-family);
}