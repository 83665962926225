.dashboard-main {
  width: 100%;
}

.dashboard-content {
  background-color: var(--color-gray);
  padding: 2rem;
  height: 100%;
}

.dashboard-flex-wrapper {
  display: flex;
  min-height: 100vh;
}

.dashboard-header {
  background-color: var(--color-white-off);
  height: 7rem;
  padding: 1.4rem 2.5rem 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.dashboard-header p {
  margin: 0;
}

.dashboard-header-sub-text {
  color: var(--color-gray-500);
}

.dashboard-header-user-image-container img {
  border-radius: 100%;
  width: 2.5rem;
}

.dashboard-header-main-text {
  font-size: 1.5rem;
  margin: 0;
  flex-grow: 1;
}

#header-dropdown {
  display: flex;
  align-items: center;
  gap: 2rem;
}

#header-dropdown svg {
  width: 0.7rem;
}

#header-dropdown::after {
  display: none;
  border: none;
}

.header-dropdown-toggle-text {
  font-weight: 500;
}

.dashboard-page {
  display: flex1;
}

.dashboard-page-title {
  font-size: 1.5rem;
  margin: 1rem 0 1.5rem 0;
  font-family: var(--default-font-family-Inter);
}

.dashboard-page-subtitle {
  color: var(--color-gray-500);
  font-family: var(--default-font-family-Inter);

}

.dashboard-header .case-content-container {
  display: contents;
  justify-content: space-between;
}

.dashboard-header .case-name {
  font-family: var(--default-font-family-Syne);
  font-size: var(--default-font-size-18);
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  color: var(--Grays-1000, #101010);
}



.dashboard-header .criminal-no {
  font-family: var(--default-font-family-Syne);
  font-size: var(--default-font-size-18);
  color: var(--color-gray-600);
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
}

.dashboard-header-user-character-container{
  height: 40px;
  width: 40px;
  background-color: var(--color-gray-500);
  color: white;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}