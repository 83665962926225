.processing-message-bubble {
  display: flex;
  gap: 0.188rem;
  width: 6.25rem;
  border-radius: 0 8px 8px 8px;
  margin-top: 0.5rem;
}

.processing-message-dot {
  width: 0.188rem;
  height: 0.188rem;
  border-radius: 50%;
  animation: dotPulse 1.5s infinite ease-in-out;
}

.processing-message-dot:nth-child(2) {
  animation-delay: 0.3s;
}

.processing-message-dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes dotPulse {
  0%,
  80%,
  100% {
    background-color: var(--color-gray-600);
    transform: scale(1);
  }
  40% {
    background-color: lightgrey;
    transform: scale(1.3);
  }
}

.chat-loader-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
}

.chat-loader-section {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  perspective: 800px;
  animation: blink 1.5s ease-in-out infinite;
}

.chat-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.chat-loader.inner-one {
  left: 0%;
  top: 0%;
  animation: rotate-one 1s linear infinite;
  border-bottom: 3px solid var(--color-gray-600);
}

.chat-loader.inner-two {
  right: 0%;
  top: 0%;
  animation: rotate-two 1s linear infinite;
  border-right: 3px solid var(--color-gray-600);
}

.chat-loader.inner-three {
  right: 0%;
  bottom: 0%;
  animation: rotate-three 1s linear infinite;
  border-top: 3px solid var(--color-gray-600);
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
}
.searching-the-web-text {
  font-size: var(--default-font-size-18);
  color: transparent;
  background-image: linear-gradient(to right, var(--color-gray-600), var(--color-grey-300), var(--color-gray-600));
  background-size: 200% 100%;
  background-position: -100% 0;
  -webkit-background-clip: text;
  background-clip: text;
  animation: textColorShift 2s ease-in-out infinite;
}

@keyframes textColorShift {
  0% {
    background-position:  100% 0;
  }
  50% {
    background-position: 0 0;
  }
  100% {
    background-position: -100% 0 ;
  }
}
