.chat-item-text-container {
  width: 100%;
  padding: 1rem;
  border-radius: 0rem 1rem 1rem 1rem;
  background: var(--Grays-0, #ffffff);
  box-shadow: 0rem 0.3125rem 3.125rem 0rem #34343408;
}

.chat-item-content-container {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
}

.chat-item-content-container p {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.6875rem;
  text-align: left;
  color: var(--Grays-800, #1d2939);
  padding: 0px;
  margin: 0px;
}

.chat-item-text-container img {
  width: 1.16625rem;
  height: 2.3325rem;
}

.message-card-footer {
  margin-top: 1.5rem;
}

.message-card-download-container,
.message-card-footer {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.message-card-download-container {
  cursor: pointer;
}

.message-card-footer span {
  text-decoration: underline;
  color: var(--color-dark-green);
  font-family: var(--default-font-family-Dm-Sans);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: left;
}

.message-card-retry-icon {
  cursor: pointer;
}

.generated-data-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: left;
  margin-top: 1.875rem;
}

.generated-data-container strong {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
}
