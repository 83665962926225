.chatroom-layout-container {
  display: flex;
  height: 100vh;
  overflow-y: scroll;
}

.chatroom-left-sidebar {
  width: 98px;
  border-right: 1px solid rgba(163, 160, 160, 0.12);
  background: var(--color-white);
  padding-bottom: 44px;
}

.chat-room-sidebar-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  height: 88%;
}

.atticus-chat-logo-container {
  height: 117px;
  border-bottom: 1px solid #ededed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: auto;
  margin-bottom: 1rem;
}

.atticus-chat-logo-container img {
  max-height: 38px;
}

.atticus-chat-icon-container {
  max-height: 24px;
  max-width: 24px;
}

.atticus-chat-side-icon-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 1rem;
}

.chatroom-content-container {
  flex: 1;
  padding: 0 44px;
  background: var(--color-gray-50);
}

.chatroom-right-sidebar {
  width: 17.5rem;
  padding: 2.5rem 1.25rem;
  border-left: 2px solid rgba(163, 160, 160, 0.12);
  background: var(--color-white);
}

.chat-button--primary {
  padding: 16px 24px;
}

.chat-button--primary img {
  margin-left: 0;
}

.layout-logout-atticus-chat-sidebar-icon{
  background-color: var(--color-white);
  padding: 0 0.3125rem 0 0;
}