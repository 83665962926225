.legal-assistant-section {
  width: 100%;
  padding: 4rem 0;
  background-color: #f9fafb;
  padding-bottom: 112px;
}

.legal-assistant-container {
  max-width: 76rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.6rem;
}

.legal-assistant-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.legal-assistant-left-image {
  width: 100%;
  max-width: 28.125rem;
  border-radius: 0.5rem;
}

.legal-assistant-left-image-container {
  position: relative;
}

.legal-assistant-right-text {
  max-width: 36.875rem;
}

.legal-assistant-section h2 {
  font-family: var(--default-font-family-Syne);
  font-size: 3rem;
  font-weight: 600;
  line-height: 4.625rem;
  text-align: center;
  margin-bottom: 2.6rem;
  text-align: center;
  max-width: 58.625rem;
  margin: auto;
  gap: 1.5rem;
}

.legal-assistant-right-text p {
  font-family: var(--default-font-family-Dm-Sans);
  color: var(--color-gray-600);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
}

.legal-assistant-bottom-right-card {
  position: absolute;
  max-width: 19.688rem;
  min-height: 11.464rem;
  width: 100%;
  top: 26.125rem;
  left: 15.375rem;
  padding: 1.18625rem 1.58125rem 1.18625rem 1.58125rem;
  gap: 1.265rem;
  border-radius: 0.31625rem;
  background-color: #f9fafb;
  box-shadow: rgba(99, 99, 99, 0.2) 0 0.125rem 0.5rem 0;
}

.legal-assistant-bottom-right-card-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
}

.legal-assistant-bottom-right-card-heading h4 {
  font-family: Inter;
  font-size: 0.79125rem;
  font-weight: 500;
  line-height: 0.956875rem;
  text-align: left;
  margin: 0;
}

.legal-assistant-close-icon {
  height: 1rem;
  width: 1rem;

  transition: transform 0.3s ease, opacity 0.3s ease;
}

.legal-assistant-left-center-card {
  position: absolute;
  background-color: var(--color-white);
  width: 10rem;
  top: 10.3375rem;
  left: -15%;
  padding: 0.75625rem 1.008125rem 0.75625rem 1.008125rem;
  gap: 0.80625rem;
  border-radius: 0.201875rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 0.0625rem 0.1875rem 0, rgba(27, 31, 35, 0.15) 0 0 0 0.0625rem;
}

.legal-assistant-top-right-card {
  position: absolute;
  min-width: 16.375rem;
  top: 8%;
  left: 63%;
  background: var(--color-white);
  padding: 0.97875rem;
  gap: 0.97875rem;
  border-radius: 0.489375rem;
  box-shadow: rgba(9, 30, 66, 0.25) 0 0.25rem 0.5rem -0.125rem, rgba(9, 30, 66, 0.08) 0 0 0 0.0625rem;
}

.legal-assistant-file-container {
  display: flex;
  align-items: center;
  padding: 0;
  border: 0.0575rem dotted var(--color-gray-200);
  border-radius: 0.5rem;
  gap: 0.5rem;
}

.legal-assistant-top-right-card-text {
  font-family: DM Sans;
  font-size: 0.570625rem;
  font-weight: 400;
  line-height: 0.815625rem;
  letter-spacing: 0.017312rem;
  text-align: left;
}

.legal-assistant-summarize-button {
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  width: 4.28875rem;
  height: 1.305rem;
  padding: 0.1875rem;
  gap: 0.32625rem;
  margin-left: 0.625rem;
  border-radius: 0.32625rem;
  border: 0.040625rem solid var(--color-green);
  font-family: var(--default-font-family-Dm-Sans);
  font-size: 0.489375rem;
  font-weight: 500;
  line-height: 0.73375rem;
  text-align: center;
  color: var(--color-gray-900);
}

.data-driven-container span {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: 0.403125rem;
  font-weight: 400;
  line-height: 0.605rem;
  text-align: left;
  color: var(--Grays-700, #344054);
}

.data-driven {
  display: flex;
  gap: 0.9375rem;
  align-items: center;
}

.data-driven-container {
  display: flex;
  flex-direction: column;
  gap: 0.3025rem;
}

.data-driven-icons-margin {
  margin-left: -0.25rem;
}

.data-driven-header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.data-driven-header-container h4 {
  font-family: Inter;
  font-size: 0.50375rem;
  font-weight: 500;
  line-height: 0.61rem;
  text-align: left;
  margin: 0;
  padding: 0;
}

.legal-assistant-bottom-right-card-body textarea {
  max-width: 16.525rem;
  width: 100%;
  min-height: 3.558125rem;
  padding: 0.6325rem;
  gap: 0.395625rem;
  border-radius: 0.31625rem;
  border: 0.039375rem solid var(--color-gray-200);
  font-family: var(--default-font-family-Dm-Sans);
  font-size: 0.6325rem;
  font-weight: 400;
  line-height: 0.94875rem;
  text-align: left;
  outline: none;
  transition: outline-color 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 0.1rem;
}

.legal-assistant-bottom-right-card-body textarea:focus {
  outline: 0.125rem solid var(--color-grey-400);
  box-shadow: 0 0 0 0.25rem rgba(94, 82, 82, 0.2);
}

.legal-assistant-bottom-right-card-body textarea::placeholder {
  color: var(--color-grey-400);
  font-family: DM Sans;
  font-size: 0.6325rem;
  font-weight: 400;
  line-height: 0.94875rem;
  text-align: left;
}

@media (max-width: 89.375rem) {
  .legal-assistant-section {
    padding-left: 5%;
    padding-right: 5%;
  }
  .legal-assistant-left-center-card {
    max-width: 10rem;
    top: 10.3375rem;
    left: -5%;
  }

  .legal-assistant-top-right-card {
    left: 55%;
  }
  .legal-assistant-bottom-right-card {
    left: 45%;
  }
}

@media (max-width: 79.125rem) {
  .legal-assistant-left-center-card {
    max-width: 10rem;
    top: 10.3375rem;
    left: -5%;
  }

  .legal-assistant-top-right-card {
    left: 42%;
  }
  .legal-assistant-bottom-right-card {
    left: 30%;
    bottom: 3%;
  }
}

@media (max-width: 75rem) {
  .legal-assistant-left-center-card {
    max-width: 10rem;
    top: 10.3375rem;
    left: -5%;
  }

  .legal-assistant-top-right-card {
    left: 40%;
  }
  .legal-assistant-bottom-right-card {
    top: 73%;
    left: 28%;
  }
}

@media (max-width: 72.5rem) {
  .legal-assistant-content {
    flex-direction: column;
    max-width: 470px;
    gap: 5rem;
    justify-content: center;
    margin: auto;
  }
}

@media (max-width: 48rem) {
  .left-image img {
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .legal-assistant-right-text {
    padding-left: 0;
    text-align: center;
  }
}

@media (max-width: 33.75rem) {
  .legal-assistant-left-center-card {
    top: 28%;
    left: 0%;
  }

  .legal-assistant-top-right-card {
    left: 20%;
    right: 1%;
  }
  .legal-assistant-bottom-right-card {
    top: 73%;
    right: 1%;
    left: 0%;
  }
}

@media (max-width: 26.25rem) {
  .legal-assistant-left-center-card {
    top: 24%;
    left: 0%;
  }

  .legal-assistant-top-right-card {
    top: 2%;
    left: 0%;
    right: 1%;
  }
  .legal-assistant-bottom-right-card {
    top: 73%;
    right: 1%;
    left: 0%;
  }

  .legal-assistant-content {
    max-width: 400px;
    gap: 8rem;
  }
}

@media (max-width: 23.438rem) {
  .legal-assistant-left-center-card {
    top: 16%;
    left: 0%;
  }

  .legal-assistant-top-right-card {
    top: -15%;
    left: -3%;
    right: 1%;
  }

  .legal-assistant-content {
    max-width: 25rem;
    gap: 10rem;
  }
}
