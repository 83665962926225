.nav-bar-list-item {
  display: flex;
  padding: 0.75rem 0 0.75rem 1.2rem;
  gap: 0.8rem;
  align-items: center;
  border-radius: 7px;
  margin: 0.05rem 0;
  position: relative;
}

.nav-bar-list-item p {
  margin: 0;
  font-family: var(--default-font-family-Dm-Sans);
}


.nav-bar-list-item svg path {
  stroke: var(--color-gray-800);
  stroke-width: 2px;
}



.active .nav-bar-list-item {
  background: var(--color-gray-800);
}

.active .nav-bar-list-item p {
  color: var(--color-white);
}

.active .nav-bar-list-item svg path {
  stroke: var(--color-white);
}