.hero-section-container {
  width: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 20%, rgba(89, 190, 181, 0.1) 50%, rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 1) 100%), url("./../../../../../assets/images/atticus/backgrounds/hero/background-image.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.hero-section {
  position: relative;
  padding: 4rem 2rem 0;
  color: white;
  text-align: center;
  overflow: hidden;
}

.hero-content {
  position: relative;
  max-width: 75rem;
  margin: auto;
}

.hero-content h1 {
  font-size: 4.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  line-height: 4.625rem;
  text-align: center;
  font-family: var(--default-font-family-Syne);
  color: #101828;
}

.hero-content p {
  font-size: var(--default-font-size-18);
  max-width: 50rem;
  margin: 2rem auto;
  font-family: var(--default-font-family-Dm-Sans);
  font-weight: 400;
  line-height: 1.688rem;
  text-align: center;
  color: var(--color-gray-900);
}

.hero-tools {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin: 2rem auto;
  max-width: 75rem;
}

.hero-tools a {
  display: flex;
  align-items: center;
  font-size: var(--default-font-size-16);
  font-family: var(--default-font-family);
  color: var(--color-gray-700);
  text-decoration: none;
  padding: 1rem 1rem;
  border-bottom: 1px solid var(--color-green-20);
  min-width: 12.313rem;
}

.hero-tools a:hover {
  border-bottom: 2px solid var(--color-light-green);
}

.hero-tools img {
  margin-right: 0.5rem;
}

.hero-newsletter form {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: auto;
  max-width: 43.75rem;
  margin-bottom: 1.5rem;
}

.hero-newsletter input {
  width: 100%;
  border: 1px solid var(--color-gray-500);
  color: var(--color-gray-900);
}

.hero-sub-description {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.313rem;
  text-align: center;
  color: var(--color-gray-800);
}

.hero-section-youtube-container {
  margin: 5rem auto 1.5rem;
  max-width: 76rem;
  height: auto;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.hero-section-youtube-container iframe {
  height: auto;
  width: 100%;
}
.hero-newsletter button {
  padding: 1rem;
  min-width: 15rem;
  width: 100%;
  cursor: default !important;
}

.text-field-hero-section {
  border: 1px solid var(--color-grey-400);
  border-radius: 8px;
}

@media (min-width: 48rem) {
  .hero-section-youtube-container iframe {
    height: 37.5rem;
    width: 100%;
  }
  .hero-newsletter input {
    min-width: 405px;
  }
}

@media (max-width: 48rem) {
  .hero-newsletter form {
    flex-wrap: wrap;
  }

  .hero-content h1 {
    font-size: 2.3rem;
    font-weight: 600;
  }
}

@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 2rem;
    font-weight: 600;
  }
}
