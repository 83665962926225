.collapsible-card {
    border-radius: 8px;
    padding: 1.5rem;
    width: 100%;
    overflow: hidden;
}

.collapsible-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.collapsible-card-title {
    font-family: var(--default-font-family-Syne);
    font-size: var(--default-font-size-18);
    font-weight: 600;
    line-height: 21.6px;
    text-align: left;
    color: var(--color-black);
    margin: 0;
}

.dropdown-arrow {
    width: 12px;
    height: 12px;
    transition: transform 0.3s ease;
}

.dropdown-arrow.open {
    transform: rotate(180deg);
}

.collapsible-card-content {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-in-out, opacity 0.3s ease-in-out,
        margin-top 0.4s ease-in-out;
    margin-top: 0;
}

.collapsible-card-content.open {
    opacity: 1;
    margin-top: 2rem;
    font-family: var(--default-font-family-Dm-Sans);
}

.collapsible-card-content.closed {
    max-height: 0;
    opacity: 0;
    margin-top: 0;
}

.collapsible-card-refresh-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.refresh-text-collapsible-card {
    color: var(--color-grey-400);
}

.retry-icon-collapsible-card {
    width: 1rem;
}