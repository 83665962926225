.motion-container {
  max-width: 20rem;
  width: 100%;
  min-height: 100vh;
  padding: 2.5rem 1.25rem 2.5rem 1.25rem;
}

.cases-motion-title {
  font-family: var(--default-font-family-Syne);
  font-size: var(--default-font-size-18);
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
}

.motion-container h4 {
  font-size: 1rem;
  font-family: var(--default-font-family-Dm-Sans);
}

.motion-recommendations-container {
  margin-top: 100px;
}

.motion-recommendations-container h4{
  font-family: var(--default-font-family-Dm-Sans);
}


.motion-recommendations-container .recommendation-button {
  margin: 0.5rem 0;
  width: 100%;
  font-weight: 400;
  font-size: 0.8rem;
  font-family: var(--default-font-family-Dm-Sans);
}

.motion-recommendations-container select {
  background-color: var(--color-gray-100);
  border: none;
  color: var(--color-gray-700);
  padding: 0.8rem;
  margin-bottom: 30px;
}

.motion-recommendations-container .generate-recommendation-button {
  background-color: var(--color-gray-900);
  color: #fff;
  margin: 1rem 0;
  width: 100%;
  margin-bottom: 40px;
}

.recommendation-button.isActive {
  background: var(--gradient-green-teal);
  color: #fff;
  border: none;
}

.recent-motion {
  color: var(--color-dark-green);
  font-family: var(--default-font-family-Dm-Sans);
  margin: 15px 0;
}

.recent-motions-container{
  margin-top: 1rem;
}

.motion-loading-message{
  display: flex;
  justify-content: center;
  align-items: center;
}

.motion-recommendations-title-container{
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.retry-atticus-recommends{
  padding: 0.25px 0.5rem;
  font-size: 0.7rem;
  font-family: var( --default-font-family-Dm-Sans);
  color: var(--color-white);
  margin-bottom: 0.3rem;
  border-radius: 0.3rem ;
  cursor: pointer;
}

.motion-options-dropdown-container{
  position: relative;
}

.motion-options-select-button{
  background-color: var(--color-gray-100);
  border: none;
  color: var(--color-gray-700);
  padding: 0.8rem;
  margin-bottom: 1.875rem;
  border-radius: 0.5rem;
  width: 100%;
  height: 3rem;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  overflow-x: scroll; 
  overflow-y: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
}

.motion-options-select-button::-webkit-scrollbar {
  height: 0.4rem;
}

.motion-options-select-button::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-700); 
  border-radius: 0.625rem;
}

.motion-options-select-button::-webkit-scrollbar-track {
  background: transparent;
}

.motion-options-dropdown{
  position: absolute;
  top: 3.75rem;
  width: 100%;
  border-radius: 1rem;
  border: 2px solid #F2F4F7;
  padding: 1rem 1.5rem;
  background-color: #FFFFFF;
  z-index: 10;
}

.motion-options-category-column{
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.motion-options-category-button{
  border: none;
  outline: none;
  font-size: var(--default-font-size-14);
  color: var(--color-gray-700);
  font-weight: 500;
  font-family: var(--default-font-family-Dm-Sans);
  line-height: 21px;
  background-color: transparent;
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.category-dropdown-arrow {
  width: 0.6rem;
  height: 0.6rem;
  transition: transform 0.3s ease;
}

.category-dropdown-arrow.open {
  transform: rotate(180deg);
}

.border-bottom-motion-category{
  border: 1px solid var(--color-gray-200);
  opacity: 1;
  margin-bottom: 0;
  margin-top: 0.125rem;
}

.motion-category-group:last-child .border-bottom-motion-category {
  border: none;
}

.motion-options-options-container{
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-top: 0.5rem;
}

.motion-options-option-list {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 4px 0.5rem;
  cursor: pointer;  
}

.motion-option-bullet {
  color: var(--color-gray-500);
  font-size: 0.75rem;
}

.motion-option-text {
  font-size: 0.75rem;
  color: var(--color-gray-500);
  font-weight: 500;
  font-family: var(--default-font-family-Dm-Sans);
  line-height: 18px;
}

.motion-options-option-list:hover{
  background-color: var(--color-gray-200);
  border-radius: 0.2rem;
}