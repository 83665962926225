.text-field-wrapper {
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
}

.text-field {
  width: 100%;
  height: 100%;
  padding: 16px 24px;
  border-radius: 8px;
  border: 1px solid var(--color-border-default);
  background: var(--color-bg-default);
  box-sizing: border-box;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.text-field--primary {
  border-image-source: var(--gradient-semi-transparent-gray);
  border-image-slice: 1;
  border-image-width: 1px;
}

.text-field:focus {
  border-color: var(--color-border-focus);
  outline: none;
}

.text-field:disabled {
  opacity: 0.9;
  background: var(--color-bg-disabled);
  border-color: var(--color-border-disabled);
}

.eye-icon {
  position: absolute;
  right: 16px;
  cursor: pointer;
  height: 24px;
  width: 24px;
}
