.chat-research-message {
  border-radius: 0 8px 8px 8px;
  margin: 10px 0;
  width: fit-content;
  justify-content: center;
  padding: 10px;
  display: flex;
  background-color: white;
  flex-direction: column;
  text-align: left;
  font-family: var(--default-font-family-Dm-Sans);
}

.chat-research-date {
  font-family: var(--default-font-family-Dm-Sans);
}

.chat-research-message p {
  margin: 0;
}

.chat-research-message-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.chat-research-message.user-chat-message {
  margin-left: auto;
  background-image: var(--gradient-green-dark-green);
  color: white;
  padding: 30px 10px;
  border-radius: 8px 0 8px 8px;
}

.chat-research-message .atticus-icon {
  flex: flex-start;
  margin-left: -20px;
}
