.data-table-container {
  width: 100%;
}

.data-table-container tr {
  height: 3.938rem;
  border-bottom: 1px solid var(--color-gray-200);
}

.data-table-container thead {
  border-bottom: 1px solid var(--color-gray-200);
}

.data-table-row.active {
  background-color: var(--color-gray-100);
}

.data-table-container tr:last-child {
  border-bottom: none;
}

.data-table-container th td {
  white-space: nowrap;
}

.data-table-container th {
  padding: 0.75rem;
  text-align: left;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-14);
  font-weight: 700;
  line-height: 21px;
  color: var(--Grays-700, #344054);
}

.data-table-container td {
  padding: 0.75rem;
  text-align: left;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-14);
  font-weight: 400;
  line-height: 21px;
  color: var(--Grays-800, #1d2939);
}

.data-table-container th:first-child {
  text-align: left;
}

.data-table-container td:first-child {
  text-align: left;
}

.data-table-container .status {
  background-color: var(--color-green-20);
  color: var(--color-dark-green);
  padding: 4px 12px 4px 12px;
  border-radius: 0.25rem;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
}

.data-table-container .status.open {
  background-color: var(--color-green-20);
  color: var(--color-dark-green);
}

.data-table-container .status.pending {
  background-color: #fff3cd;
  color: #856404;
}

.data-table-container .status.closed {
  background-color: #f8d7da;
  color: #721c24;
}

.data-table-container .action-icon {
  color: var(--color-gray-500);
  cursor: pointer;
}

.data-table-container::-webkit-scrollbar {
  height: 0.5rem;
}

.data-table-container::-webkit-scrollbar-track {
  background: transparent;
}

.data-table-container::-webkit-scrollbar-thumb {
  background: var(--color-green-20);
  border-radius: 4px;
}

.data-table-container::-webkit-scrollbar-thumb:hover {
  background: var(--color-dark-green);
}

.table-footer-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 2rem;
}

.Page-of-items {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-14);
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--Grays-900, #101828);
}

@media (max-width: 62.5rem) {
}
