.add-team-member {
    width: 100%;
    max-width: 25rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.add-team-member-input-group {
    display: flex;
    gap: 10px;
}

.text-field-wrapper {
    width: 100%;
    position: relative;
}

.add-team-member input {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
    font-size: var(--default-font-size-14);
}

.eye-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.add-team-member .dropdown-container {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--color-gray-200);
    border-radius: 0.5rem;
    font-size: var(--default-font-size-16);
    min-height: 56px;

}

.add-team-member-products {
    display: flex;
    flex-direction: column;
}

.add-team-member-products-options {
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    margin-top: 10px;
}

.add-team-member-products-option {
    border: 1px solid var(--color-gray-200);
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-14);
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    padding: 12px 16px 12px 16px;
    gap: 8px;
    border-radius: 100px;
}

.add-team-member-products-option.selected {
    background-color: var(--color-gray-700);
    color: #fff;
}

.send-invite-button {
    padding: 0.938rem;
    border: none;
    border-radius: 25px;
    background: linear-gradient(90deg, #00C853 0%, #B2FF59 100%);
    color: white;
    font-size: var(--default-font-size-16);
    cursor: pointer;
    transition: background 0.3s ease;
}

.send-invite-button:hover {
    background: linear-gradient(90deg, #00B248 0%, #A1FF50 100%);
}