.chat-feature-card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
    border-radius: 16px;
    border: 2px;
    border: 2px solid var(--Grays-200, #EAECF0)
}

.chat-feature-icon {
    width: 2.5rem;
    height: 2.5rem;
}

.chat-feature-heading{
display: flex;
gap: 1rem;
align-items: center;
}

.chat-feature-heading h3 {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-18);
    font-weight: 500;
    line-height: 1.688rem;
    text-align: center;
    color: var(--Grays-1000, #101010);
    margin: 0;
    padding: 0;
}

.chat-feature-content p {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-16);
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
}