.upload-document-page {
    padding: 1.5rem;
}

.upload-document-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.upload-documents-info {
    flex: 1;
}

.upload-documents-info h4 {
    font-family: var(--default-font-family-Syne);
    font-size: var(--default-font-size-18);
    font-weight: 600;
    line-height: 21.6px;
    text-align: left;

}

.upload-documents-info p {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-16);
    font-weight: 400;
    line-height: 19.36px;
    text-align: left;
}

.upload-button-container {
    margin-left: 1.25rem;
}

.button-content {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding: 0.42rem 1rem 0.42rem 1rem
}

.button-content span {
    color: white;
}