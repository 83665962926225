.motion-modal-page-1 textarea {
  display: block;
  width: 100%;
  height: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.motion-modal-page-1 .common-button {
  color: #fff;
  width: 100%;
}

.download-motion-button {
  width: 100%;
}

.motion-download-disabled {
  color: var(--color-gray-500) !important;
  border-color: var(--color-gray-500) !important;
}

.motion-download-disabled svg {
  /* fill: var(--color-gray-500) !important; */
  stroke: var(--color-gray-500) !important;
}

.download-motion-confirm-container form {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.download-motion-confirm-container input {
}

.download-motion-confirm-container p {
  color: var(--color-gray-500);
}

.no-click {
  pointer-events: none;
  cursor: default;
}