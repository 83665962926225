.new-search-case-modal{
    width: 100%;
    min-width: 31rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.new-search-case-group label{

    display: block;
    margin-bottom: 0.5rem;
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-14);
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
    color: var(--color-gray-500);
}

.new-search-case-input{
    width: 100%;
    font-size: var(--default-font-size-16);
    border: 1px solid #ccc;
    border-radius: 0.25rem;
}

.new-search-case-date-picker-group{
    display: flex;
    gap: 0.75rem;
}

.new-search-case-date-picker-group .new-search-case-group{
    width: 50%;
}

.new-search-case-modal .loader-spinner-rotating-lines {
    margin: 0.15rem auto;
}

.search-case-loader-spinner-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    margin: auto;
    gap: 0.8rem;
}