.compare-top-heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.drag-file-upload-main-container {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.compare-top-heading-container div {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.compare-top-heading-container h6 {
  margin: 0;
  padding: 0;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-18);
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: var(--Grays-900, #101828);
}
.compare-top-heading-container p {
  margin: 0;
  padding: 0;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-16);
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: var(--Grays-800, #1d2939);
}
.generate-table-button {
  color: var(--color-white);
  font-size: var(--default-font-size-14);
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

.generate-table-button.disabled {
  background: var(--Grays-300, #d0d5dd);
  cursor: not-allowed;
}
