.team-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin-bottom: 12px;
}

.case-team-left {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.case-team-details {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.case-team-details h2 {
    font-family: var(--default-font-family-Syne);
    font-size: var(--default-font-size-18);
    font-weight: 600;
    line-height: 21.6px;
    text-align: left;
    color: var(--color-black);
    margin: 0;
}

.case-team-details p {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-18);
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: var(--color-gray-500);
    margin: 0;
}

.case-team-right {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.manage-team-link {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-16);
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    text-decoration: underline;
    color: var(--color-gray-800);
}

.case-add-members-btn {
    color: var(--color-white);
}