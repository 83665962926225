.add-new-member-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 26.125rem;
}

.team-member-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-gray-50);
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
}

.member-info {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}

.member-avatar {
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
}

.team-member-card-content {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.member-name {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  font-family: var(--default-font-family-Dm-Sans);
  color: var(--color-gray-800);
  margin-bottom: 0;
}

.member-role {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.313rem;
  color: var(--color-gray-800);
  font-family: var(--default-font-family-Dm-Sans);
  margin-bottom: 0;
}

.member-actions {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}

.add-button {
  color: var(--color-gray-900);
  cursor: pointer;
  font-family: var(--default-font-family);
  border-radius: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.125rem;
  text-align: center;
}

.added-button.common-button {
  background-color: var(--color-white) !important;
  border: 1px solid var(--color-gray-200);
  color: black !important;
  font-family: var(--default-font-family);
  border-radius: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.125rem;
  text-align: center;
  opacity: 1 !important;
}

.delete-icon {
  width: 1.125rem;
  height: 1.125rem;
  cursor: pointer;
}
