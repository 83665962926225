.analyze-message-card {
  width: 100%;
  padding: 1rem;
  border-radius: 0rem 1rem 1rem 1rem;
  background: var(--color-white);
  box-shadow: 0rem 0.3125rem 3.125rem 0rem #34343408;
}

.analyze-message-card-content {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
}

.analyze-message-card-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: left;
  margin-top: 1rem;
}

.analyze-message-card-details strong {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-18);
  font-weight: 600;
  line-height: 27px;
  text-align: left;
}

.analyze-message-card-text {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}
.analyze-message-card-text span {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-18);
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: var(--Grays-800, #1d2939);
}

.analyze-message-card-text p {
  text-align: left;
  padding: 0;
  margin: 0;
}
