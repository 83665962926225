.page-not-found-container {
  min-height: 100vh;
  background: var(--color-gray-100);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  font-family: var(--default-font-family);
}

.page-not-found-content-container {
  max-width: 32rem;
  width: 100%;
  text-align: center;
}

.page-not-found-title {
  font-size: 9rem;
  font-weight: 800;
  color: var(--color-dark-green);
  letter-spacing: 0.2em;
}

.page-not-found-subtitle {
  margin-top: 1.5rem;
  font-size: 2rem;
  font-weight: 700;
  color: #1f2937;
  letter-spacing: 0.5px;
}

.page-not-found-description {
  margin-top: 1rem;
  font-size: 1.125rem;
  color: #4b5563;
  letter-spacing: 0.5px;
}

.page-not-found-btn {
  margin-top: 2rem;
  font-weight: bold;
  font-size: large;
  width: 21rem;
}
