.generate-motion-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.generate-motion-modal .modal-content {
  width: 600px;
  padding: 30px;
}

.generate-motion-modal .modal-header {
  border: none;
  font-family: var(--default-font-family-Inter);
  font-weight: light;
}
.generate-motion-modal .motion-advance-button {
  width: 100%;
  color: white;
  padding: 20px 0;
}

.case-recommendation-panel {
  background-color: var(--color-gray-50);
  box-sizing: border-box;
  border: solid 2px transparent; /* !importanté */
  border-radius: 3px;
  color: var(--color-gray-600);
  font-family: var(--default-font-family-Dm-Sans);
}

.case-recommendation-name {
  color: var(--color-gray-900);
  font-size: 1.2rem;
}

.case-recommendation-description {
  color: var(--color-gray-500);
  font-size: 1.1rem;
}

.case-recommendation-panel-container {
  border-radius: inherit;
  background-color: var(--color-gray-400);
  padding: 3px;
  border-radius: 3px;
  margin: 10px 0;
}

.case-recommendation-panel-container.isSelected {
  background: var(--gradient-green-teal);
}

.case-recommendation-panel p {
  margin: 1rem;
}

.generating-motion-step {
  display: flex;
  gap: 5px;
  align-items: center;
  margin: 20px 0;
  font-family: var(--default-font-family-Dm-Sans);
}

.generating-motion-step p {
  margin: 0;
}

.generating-motion-browse-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.generating-motion-step-processing {
  animation: rotate 1.5s linear infinite; 
}

.generation-motion-confirmation-modal {
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: none;
}