.badge-info {
  background-color: var(--color-gray-700);
  color: var(--color-white);
  padding: 0.3rem 0.6rem;
  border-radius: 8px;
  font-weight: 100;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 2.5rem;
  white-space: nowrap;
}
.badge-info.additional-items {
  background: var(--gradient-green-teal);
}

.badge-info.closed {
  background: var(--color-white);
  color: var(--color-gray-700);
  border: 3px solid var(--color-gray-200);
}