.dashboard-legal-team-container {
  box-sizing: border-box;
  margin: auto;
}

.dashboard-legal-team-heading {
  font-family: Inter;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: left;
  color: var(--Grays-900, #101828);
}

.dashboard-legal-team-heading-gray {
  font-family: Inter;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: left;
  color: #6B7280;
}

.legal-team-member-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  gap: 1rem;
  margin-top: 1.5rem;
}

.legal-add-team-member-card {
  min-height: 19.063rem;
  width: 100%;
  padding: 1.5rem;
  gap: 1.5rem;
  border-radius: 0.75rem;
  background: var(--color-grey-400);
  animation-timing-function: ease-out;
  animation-duration: 300ms;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.legal-add-team-member-card:hover {
  background: var(--color-gray-500);
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.add-team-member-card-text {
  font-family: var(--default-font-family-Syne);
  font-size: var(--default-font-size-18);
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  color: var(--color-white);
}


.search-container {
  display: flex;
  gap: 1rem;
  border-radius: 0.5rem;
}

.search-input-container {
  display: flex;
  align-items: center;
  height: 37px;
  background-color: var(--color-white);
  padding: 0px 12px;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  max-width: 12.5rem;
  width: 100%;
}

.search-icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 8px;
}

.search-input {
  border: none;
  outline: none;
  width: 100%;
}


.legal-team-dashboard-sub-header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

@media (max-width: 48rem) {
  .legal-team-member-cards-container {
    gap: 1rem;
  }
}

@media (max-width: 30rem) {
  .legal-team-member-cards-container {
    gap: 12px;

  }
}