.legal-team-member-card {
    min-height: 19.063rem;
    padding: 1.5rem;
    gap: 1rem;
    border-radius: 0.75rem;
    background: var(--color-white);
    animation-timing-function: ease-out;
    animation-duration: 300ms;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.legal-team-member-image {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;
    display: block;
    background-color: var(--color-gray-500);
}

.legal-team-member-name {
    font-family: var(--default-font-family-Syne);
    font-size: var(--default-font-size-18);
    font-weight: 600;
    line-height: 1.35rem;
    text-align: center;
    margin-top: 1.5rem;
}

.legal-team-member-role {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-18);
    color: var(--color-gray-500);
    font-weight: 400;
    line-height: 1.688rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.legal-team-member-view-profile {
    background-color: var(--color-white) !important;
    border: 1px solid var(--Grays-200, #EAECF0) !important
}

.legal-team-member-role span {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--color-gray-500);
}


.legal-team-member-action-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.legal-team-member-action-container img {
    height: 18px;
    width: 18px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.legal-team-member-action-container img:hover {
    transform: scale(1.3);
}

@media (max-width: 48rem) {
    .legal-team-member-card {
        max-width: 100%;
    }
}