.docket-upload-page-main-title {
  font-family: var(--default-font-family-Inter);
  font-weight: 500;
  font-size: 20px;
  line-height: 24.2px;
  color: var(--color-gray-900);
  max-width: 471px;
  width: 100%;
}
.docket-upload-page-data-table-main {
  padding: 24px;
  gap: 32px;
  opacity: 1;
  margin-top: 20px;
}

.docket-upload-page-title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.docket-upload-page-title-section h5 {
  font-family: var(--default-font-family-Syne);
  font-size: var(--default-font-size-18);
  font-weight: 600;
}

.docket-upload-page-dropdown-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.table-data-docket-upload {
  cursor: pointer;
}

.table-data-date-entered {
  width: 8.75rem;
}
.table-data-action-menu-button {
  width: 5rem;
}

.finish-uploading-documents-container {
  display: flex;
  justify-content: center; /* Centers the button horizontally */
}

button.finish-uploading-documents {
  margin-top: 1rem;
  height: 3rem;
  color: white;
}
