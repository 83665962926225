.feature-section {
  flex-direction: column;
}

.feature-card {
  max-width: 36.875rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.feature-card h3 {
  font-family: var(--default-font-family-Syne);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.8rem;
  text-align: left;
  color: var(--color-gray-900);
}

.feature-card p {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-16);
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
}

.feature-card img {
  width: 3rem;
  height: 3rem;
}

@media (max-width: 80rem) {
  .feature-card {
    max-width: 25rem;
  }
}
@media (max-width: 68.75rem) {
  .feature-card {
    max-width: auto;
    width: 100%;
  }
  .feature-card {
    max-width: 30.5rem;
  }
}

@media (max-width: 768px) {
  .feature-card h3 {
    font-size: 1rem;
    line-height: 1.613rem;
  }

  .feature-card p {
    font-size: var(--default-font-size-14);
    line-height: 1.2rem;
  }
  .feature-card {
    gap: 1rem;
  }
}
