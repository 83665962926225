.chat-profile-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  gap: 2rem;
  text-align: center;
  height: 100vh;
}

.chat-profile-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 2rem;
  overflow-y: auto;
  scrollbar-width: none;
  padding-bottom: 2rem;
}

.chat-profile-content::-webkit-scrollbar {
  display: none;
}

.chat-profile-time {
  font-family: Poppins;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  color: var(--Grays-500, #667085);
  margin-bottom: 1rem;
}

.message-container {
  max-height: 400px;
  overflow-y: auto;
}
