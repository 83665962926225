.chat-input-box {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-radius: 1rem;
  padding: 1.5rem;
  border: 2px solid var(--Grays-200, #eaecf0);
  width: 100%;
  margin-bottom: 1rem;
}

.chat-input-container {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  gap: 1rem;
}

.chat-attachment-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-grey-400);
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}

.attachment-icon {
  width: 20px;
  height: 20px;
}

.chat-message-text {
  flex-grow: 1;
}

.chat-message-input {
  width: 100%;
  max-height: 700px;
  border: none;
  outline: none;
  padding: 10px;
  background-color: transparent;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-18);
  font-weight: 400;
  line-height: 1.688rem;
  text-align: left;
  color: var(--color-gray-800);
  resize: none;
  overflow-y: hidden;
}

.chat-message-input::-webkit-scrollbar {
  width: 8px;
}

.chat-message-input::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 10px;
}

.chat-message-input::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.chat-message-input::placeholder {
  color: var(--Grays-500, #667085);
}

.send-message-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gradient-green-teal);
  border-radius: 0.625rem;
  width: 3.25rem;
  height: 3.25rem;
  margin-left: 0.625rem;
}

.send-message-icon {
  width: 1.5rem;
  height: 1.5rem;
}
