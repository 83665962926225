.main-criminal-docket-case-container {
  display: flex;
  flex-direction: column;
  gap: 1.375rem;
  padding: 1rem 1.75rem;
}

.docket-case-upload-file .drag-file-upload-content {
  flex-direction: column;
  gap: 1.125rem;
  margin-top: 0.625rem;
}
.docket-case-upload-file .drag-file-upload {
  display: block;
}

.criminal-case-file-upload-status-item {
  max-width: 25rem;
  width: 100%;
  display: flex;
  padding: 1.5rem 0.5rem;
  gap: 1.125rem;
}

.criminal-case-file-upload-status-icon .file-upload-success-icon {
  font-size: var(--default-font-size-18);
  color: var(--color-dark-green);
}

.criminal-case-status-details {
  display: flex;
  flex-direction: column;
  width: 18.625rem;
}

.criminal-case-status-details p {
  font-size: var(--default-font-size-16);
  font-weight: 400;
  color: var(--color-black);
  font-family: var(--default-font-family-Dm-Sans);
  margin-bottom: 0px;
}

p .progress-status-text {
    font-size: var(--default-font-size-14);
  font-family: var(--default-font-family-Dm-Sans);
  color: var(--color-gray-600);
  font-weight: 400;
  margin-top: 0px;
}

.progress-bar-indicator {
  background-color: #f3f4f6;
  height: 0.25rem;
  overflow: hidden;
}

.progress-bar-fill {
  height: 70%;
}

.success-fill {
  width: 50%;
  background-color: var(--color-dark-green);
}

.close-icon-criminal-case {
  width: 0.8rem;
  height: 0.8rem;
  cursor: pointer;
  color: var(--color-grey-400);
}

.criminal-case-file-upload-status-item .error {
  display: flex;
}
.criminal-case-file-upload-status-icon .file-upload-error-icon {
  font-size: var(--default-font-size-18);
  color: #d32f2f;
}

.status-detail-error-block {
  display: flex;
  flex-direction: column;
  width: 18.625rem;
  font-weight: 400;
}

.status-detail-error-block p {
  color: #d32f2f;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-16);
}

.status-detail-error-block p span {
  font-size: var(--default-font-size-14);
}
