.add-case-modal {
    width: 100%;
    min-width: 31rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.case-form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-14);
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
    color: var(--color-gray-500);
}

.case-form-group-input {
    width: 100%;
    font-size: var(--default-font-size-16);
    border: 1px solid #ccc;
    border-radius: 0.25rem;
}

.case-form-date-picker-group {
    display: flex;
    gap: 0.75rem;
}

.case-form-date-picker-group .case-form-group {
    width: 50%;
}


.cancel-btn {
    margin-top: -0.625rem;
    background: none;
    border: none;
    cursor: pointer;
    text-align: center;
    width: 100%;
}