.action-menu-button {
    border: none;
    background-color: transparent;
}

.action-menu-icon {
    width: 24px;
    height: 24px;
}

.action-menu-list {
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0.625rem;
    min-width: 6.625rem;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-300);
}

.action-menu-item {
    padding: 10px 15px;
    border-radius: 0.5rem;
    font-size: var(--default-font-size-16);
    color: var(--color-gray-700);
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    transition: background-color 0.2s, color 0.2s;
    margin-top: 3px;
    background-color: transparent;

}

.action-menu-item:hover {
    background-color: var(--color-light-green-2);
    color: var(--color-dark-green);
    border-color: var(--color-dark-green);
}

.action-menu-item svg {
    margin-right: 8px;
    color: var(--color-gray-600);
}