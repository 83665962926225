.voices-of-success-container {
  text-align: center;
  padding: 1rem;
  background: var(--color-gray-100);
  width: 100%;
  padding-top: 5rem;
}

.voices-of-success--container {
  max-width: 76rem;
  margin: auto;
  overflow: hidden;
}
.voices-of-success-title {
  margin-bottom: 1.25rem;
  font-family: var(--default-font-family-Syne);
  font-size: 3rem;
  font-weight: 600;
  line-height: 4.675rem;
  text-align: left;
  color: var(--color-gray-900);
}

.voices-of-success-slide {
  padding: 5rem;
  padding-left: 0;
  position: relative;
  display: inline-block;
  max-width: 76rem;
  min-height: 30.875rem;
  margin: 0 auto;
  cursor: pointer;
}
.voices-of-success-border {
  border-radius: 10px;
  overflow: hidden;
}
.voices-of-success-sub-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  flex-wrap: wrap;
}

.voices-of-success-sub-container p {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3.75rem;
  text-align: left;
  color: var(--color-gray-500);
}

.voices-of-success-description {
  max-width: 49.063rem;
  gap: 2rem;
}

.voices-of-success-author {
  display: flex;
  align-items: center;
  margin-top: 1.25rem;
}

.voices-of-success-author img {
  border-radius: 8.8px;
  margin-right: 10px;
  height: 5rem;
  width: 5rem;
}

.voices-of-success-author div {
  text-align: left;
}

.voices-of-success-author strong {
  display: block;
  font-family: var(--default-font-family-Syne);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.75rem;
  text-align: left;
}

.voices-of-success-author span {
  font-size: 0.9em;
  color: #8b8d98;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.875rem;
  text-align: left;
}

@media (max-width: 49.125rem) {
  .voices-of-success-slide {
    padding: 5rem 0.7rem;
  }
}
