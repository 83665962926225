.partner-section-container {
  width: 100%;
}

.partner-section-heading {
  font-family: var(--default-font-family-Syne);
  font-size: 2rem;
  font-weight: 600;
  line-height: 4.625rem;
  text-align: center;
  max-width: 76rem;
  margin: auto;
  text-align: center;
}

.partner-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 90rem;
  gap: 20px;
  margin: 0rem auto 7rem auto;
}

.partner-logos img {
  margin: 0 0.938rem;
  max-width: 100px;
  flex: 1 1 calc(20% - 20px);
}

.partner-logos span {
  width: 1px;
  height: 3.125rem;
  gap: 0px;
  background: var(--color-grey-300);
}

.partner-logos span:last-child {
  width: 0;
}

@media (max-width: 768px) {
  .partner-logos {
    justify-content: center;
    gap: 40px;
  }

  .partner-logos img {
    flex: 1 1 calc(50% - 20px);
  }

  .partner-logos span {
    display: none;
  }
}

@media (max-width: 480px) {
  .partner-logos img {
    flex: 1 1 calc(100% - 20px);
  }
}
