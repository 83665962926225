.comparison-table-container {
  background-color: var(--color-white);
  padding: 1rem;
  border-radius: 0px 1rem 1rem 1rem;
}

.comparison-table-container p {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-18);
  font-weight: 400;
  text-align: left;
}

.comparison-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.comparison-table th,
.comparison-table td {
  border: 1px solid var(--color-grey-300);
  padding: 0.5rem;
  text-align: left;
  height: 64px;
}

.comparison-table th {
  background: var(--Grays-100, #f2f4f7);
  color: var(--Grays-700, #344054);
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-14);
  font-weight: 700;
}

.first-aspect-column {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-14);
  font-weight: 700;
}
