.last-docket-entry {
    width: 100%;
    padding: 1.5rem;

}

.last-docket-entry h3 {
    font-family: var(--default-font-family-Syne);
    font-size: var(--default-font-size-18);
    font-weight: 600;
    line-height: 21.6px;
    text-align: left;
    color: var(--color-black);
    margin-bottom: 2rem;
}

.action-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.menu-button {
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.menu-button:hover {
    background-color: var(--color-gray-100);
    border-radius: 0.75rem;
}

.custom-menu-list {
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0.625rem;
    min-width: 6.625rem;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-300);

}

.custom-menu-item {
    padding: 10px 15px;
    border-radius: 0.5rem;
    font-size: var(--default-font-size-16);
    color: var(--color-gray-700);
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    transition: background-color 0.2s, color 0.2s;
    margin-top: 3px;
}

.custom-menu-item:hover {
    background-color: var(--color-light-green-2);
    color: var(--color-dark-green);
    border-color: var(--color-dark-green);
}

.custom-menu-item svg {
    margin-right: 8px;
    color: var(--color-gray-600);
}

.last-docket-entry-date {
    width: 5rem;
}

.last-docket-entry-filing {
    width: 18.75rem;
}

.last-docket-entry-menu-icon {
    width: 5rem;
}

.no-docket-entry-text {
    text-align: center;
}
