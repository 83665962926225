.legal-brief-section-container {
  width: 100%;
  background: url("./../../../../../assets/images/atticus/backgrounds/legalBrief/circle-background.svg");
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: bottom right;
  background-color: var(#b9ff981a);
}
.legal-brief-container {
  max-width: 76rem;
  width: 100%;
  margin: auto;
}

.legal-brief-heading {
  font-family: var(--default-font-family-Syne);
  font-size: 3rem;
  font-weight: 600;
  line-height: 4.625rem;
  text-align: center;
  color: var(--color-gray-900);
  margin-top: 1.5rem;
}

.legal-brief-sub-heading {
  font-family: var(--default-font-family-Syne);
  font-size: var(--default-font-size-18);
  font-weight: 600;
  line-height: 1.35rem;
  text-align: center;
  color: var(--color-dark-green);
}

.features-brief-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 3.75rem;
  margin-top: 3.75rem;
}

.features-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.8rem;
}

.features-card-container {
  position: relative;
  width: 30.5rem;
  min-height: 392px;
  border-radius: 1rem;
  background: var(--color-gray-100);
  display: flex;
  justify-content: center;
  align-items: center;
}

.atticus-recommends-card-image-wrapper {
  position: absolute;
  top: 7%;
  right: 17%;
}

.summary-card-top-image-wrapper {
  position: absolute;
  top: 9%;
  left: 4%;
}

.legal-team-card-top-image-wrapper {
  position: absolute;
  top: 9%;
  left: 10%;
}

.legal-brief-stats-container {
  text-align: center;
  margin: 3.125rem auto;
  max-width: 59.375rem;
  padding-top: 2.5rem;
}

.legal-brief-stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  flex-wrap: wrap;
}

.stat-item {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
.stat-item h3 {
  font-family: var(--default-font-family-Syne);
  font-size: 2.25rem;
  margin: 0;
}

.stat-item p {
  margin: 0;
  font-family: var(--default-font-family-Dm-Sans);
}

.legal-brief-stats-description {
  max-width: 57.813rem;
  margin: 5rem auto;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-18);
  font-weight: 400;
  line-height: 1.688rem;
  text-align: center;
}

.summary-contant-container,
.atticus-recommends,
.legal-team-contant-container {
  max-width: 30.5rem;
  padding: 1.5rem 2.5rem;
  border-radius: 1rem;
  background-color: var(--color-gray-100);
}

.summary-contant,
.atticus-recommends-content,
.legal-team-contant {
  max-width: 24.25rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0.75rem;
  gap: 0.625rem;
  border-radius: 0.5rem;
  background-color: var(--color-white);
  margin: 0.5rem;
  box-shadow: 21.4px 21.4px 57.08px 0px #132d611a;
}

.atticus-recommends-content button{ 
  font-family: var(--default-font-family-Dm-Sans);
}

.atticus-recommends-subtitle {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.97625rem;
  text-align: left;  
  font-family: var(--default-font-family-Dm-Sans);
}

.recommends-show-more-btn {
  cursor: default;
}

.summary-show-more-btn {
  border: 0.0625rem solid var(--color-light-green);
  background-color: var(--color-white);
  border-radius: 0.5rem;
  max-width: 6.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-text-font {
  font-family: DM Sans;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.125rem;
  text-align: center;
}

.summmary-eye-black-icon {
  margin-right: 0.4375rem;
}

.summary-contant {
  display: flex;
  flex-direction: column;
  gap: 2.75rem;
}

.summary-contant-paragraph{
  font-family: var(--default-font-family-EB-Garamond);
}

.show-more-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: default;
  font-family: var(--default-font-family-Dm-Sans);
}

.card-image-wrapper-end {
  display: flex;
  justify-content: end;
}

.legal-team-contant {
  padding: 1.5rem;
  gap: 2.75rem;
}

.legal-team-contant h4 {
  font-family: Syne;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.8rem;
  text-align: left;
  color: var(--color-gray-900);
  margin-bottom: 1rem;
}

.legal-team-contant span {
  font-family: DM Sans;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.688rem;
  text-align: left;
  color: var(--color-gray-500);
}

.legal-team-contant a {
  font-family: DM Sans;
  text-decoration: underline;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: var(--color-gray-800);
}

.team-images {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 0.3125rem;
}

.team-images img {
  border-radius: 50%;
  width: 2.75rem;
  height: 2.75rem;
  border: 0.095625rem solid var(--color-green);
  position: relative;
  z-index: 1;
  background-color: #49afdb;
  margin-left: -1rem;
}

.team-images img:first-of-type,
.team-images img:nth-of-type(n + 2):nth-of-type(4n + 1) {
  margin-left: 0;
}

@media (max-width: 82.5rem) {
  .legal-brief-container {
    padding: 0 5%;
  }
}

@media (max-width: 37.5rem) {
  .features-card-container {
    width: 100%;
    max-width: 23.75rem;
  }

  .atticus-recommends-card-image-wrapper {
    top: 0%;
    right: 0%;
  }

  .summary-card-top-image-wrapper {
    top: 0%;
    left: 0%;
  }

  .legal-team-card-top-image-wrapper {
    top: 0%;
    left: 0%;
  }

  .summary-contant-container,
  .atticus-recommends,
  .legal-team-contant-container {
    padding: 2% 4%;
  }

  .legal-brief-stats {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
  }
}

@media (max-width: 68.75rem) {
  .features-row {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 48rem) {
  .legal-brief-heading {
    font-size: 2rem;
    font-weight: 600;
    line-height: 3.75rem;
    margin-top: 1rem;
  }

  .legal-brief-sub-heading {
    font-size: 1rem;
  }

  .atticus-recommends {
    padding: 1.5rem 2%;
  }
}
