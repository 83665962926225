.case-summary-description {
    font-family: Inter;
    font-size: var(--default-font-size-16);
    font-weight: 400;
    line-height: 19.36px;
    text-align: left;
    color: var(--color-gray-600);
}

.case-status-card {
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.case-statis-label-text {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-14);
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--color-gray-600);
}

.case-status-content-container {
    display: flex;
    justify-content: space-between;
}

.case-status {
    background-color: var(--color-green-20);
    color: var(--color-dark-green);
    padding: 4px 12px 4px 12px;
    border-radius: 2px;
    font-family: var(--default-font-family-Dm-Sans);
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    min-width: 4.063rem;
}

.case-status-content-container .status.open {
    background-color: var(--color-green-20);
    color: var(--color-dark-green);
}

.case-status-content-container .status.pending {
    background-color: #fff3cd;
    color: #856404;

}

.case-status-content-container .status.closed {
    background-color: #f8d7da;
    color: #721c24;

}

.case-filled-date {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-14);
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--color-black);
}